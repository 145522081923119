export function injectProp(target: object, propName: string, get: () => unknown, set?: VoidFunction) {
  Object.defineProperty(target, propName, {
    get,
    set,
    enumerable: true
  })
}

export function injectMultipleProps(target: object, props: Record<string, () => unknown>) {
  for (const key in props) {
    injectProp(target, key, props[key])
  }
}
