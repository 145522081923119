import {type Component, createApp, customRef} from "vue";
import {getActivePinia} from "pinia"
import installAvv from "./_abstract/install-avv";

/** This is a preferred way for creating vue application, it will automatically inject all needed libraries. */
export const createVueApp = (rootComponent: Component, rootProps?: Record<string, unknown> | null) => {
  const app = createApp(rootComponent, rootProps)
  app.use(getActivePinia()!);
  app.use({install: installAvv})
  return app;
}
