// no extension on purpose for next one:
import defaultLang from '../langs/en-GB'
import defineReactivePlugin from '../utils/define-reactive-plugin'

function getLocale() {
  const val =
    Array.isArray(navigator.languages) === true &&
    navigator.languages.length > 0
      ? navigator.languages[0]
      : navigator.language

  if (typeof val === 'string') {
    return val
      .split(/[-_]/)
      .map((v, i) =>
        i === 0
          ? v.toLowerCase()
          : i > 1 || v.length < 4
          ? v.toUpperCase()
          : v[0].toUpperCase() + v.slice(1).toLowerCase()
      )
      .join('-')
  }
}

const Lang = defineReactivePlugin(
  {
    __langPack: {}
  },
  {
    getLocale,

    set(langObject = defaultLang, ssrContext) {
      const lang = {
        ...langObject,
        rtl: langObject.rtl === true,
        getLocale
      }

      const el = document.documentElement
      el.setAttribute('dir', lang.rtl === true ? 'rtl' : 'ltr')
      el.setAttribute('lang', lang.isoName)

      lang.set = Lang.set

      Object.assign(Lang.__langPack, lang)

      Lang.props = lang
      Lang.isoName = lang.isoName
      Lang.nativeName = lang.nativeName
    },

    install({ $avv, lang, ssrContext }) {
      $avv.lang = Lang.__langPack

      if (this.__installed === true) {
        lang !== undefined && this.set(lang)
      } else {
        this.set(lang || defaultLang)
      }
    }
  }
)

export default Lang
export { defaultLang }
