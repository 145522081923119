import { createApp } from 'vue'
import Lang from './plugins/Lang'

import Platform, { isRuntimeSsrPreHydration } from './plugins/Platform'
import Screen from './plugins/Screen'
import {
  freezeGlobalConfig,
  globalConfig,
  globalConfigIsFrozen
} from './utils/global-config'
import { isObject } from './utils/is'
import { avvKey } from './utils/symbols'

const autoInstalledPlugins: Array<{
  install: (opts: unknown) => void
  __installed?: boolean
}> = [Platform, Screen, Lang]

export function createChildApp(appCfg, parentApp) {
  const app = createApp(appCfg)

  app.config.globalProperties = parentApp.config.globalProperties

  const { reload, ...appContext } = parentApp._context
  Object.assign(app._context, appContext)

  return app
}

function installPlugins(
  pluginOpts: unknown,
  pluginList: Array<{ install: (opts: unknown) => void; __installed?: boolean }>
) {
  pluginList.forEach((Plugin) => {
    Plugin.install(pluginOpts)
    Plugin.__installed = true
  })
}

function prepareApp(app, uiOpts, pluginOpts) {
  app.config.globalProperties.$avv = pluginOpts.$avv
  app.provide(avvKey, pluginOpts.$avv)

  installPlugins(pluginOpts, autoInstalledPlugins)

  uiOpts.components !== undefined &&
    Object.values(uiOpts.components).forEach((c) => {
      if (isObject(c) && c.name !== undefined) {
        app.component(c.name, c)
      }
    })

  uiOpts.directives !== undefined &&
    Object.values(uiOpts.directives).forEach((d) => {
      if (isObject(d) && d.name !== undefined) {
        app.directive(d.name, d)
      }
    })

  uiOpts.plugins !== undefined &&
    installPlugins(
      pluginOpts,
      Object.values(uiOpts.plugins).filter(
        (p) =>
          typeof p.install === 'function' &&
          autoInstalledPlugins.includes(p) === false
      )
    )

  if (isRuntimeSsrPreHydration.value === true) {
    pluginOpts.$avv.onSSRHydrated = () => {
      pluginOpts.onSSRHydrated.forEach((fn) => {
        fn()
      })
      pluginOpts.$avv.onSSRHydrated = () => {}
    }
  }
}

export default function installAvv(parentApp, opts?: { config?: {} } = {}) {
  const $avv: {
    config?: {}
  } = {}

  if (!globalConfigIsFrozen) {
    if (opts.config !== undefined) {
      Object.assign(globalConfig, opts.config)
    }

    $avv.config = { ...globalConfig }
    freezeGlobalConfig()
  } else {
    $avv.config = opts.config || {}
  }

  prepareApp(parentApp, opts, {
    parentApp,
    $avv,
    lang: opts.lang,
    onSSRHydrated: []
  })
}
